<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      spinner="bar-fade-scale"
      color="#68D389"
      size="128"
      is-full-screen
    />
    <v-snackbar v-model="showsnackbar" color="#68D389" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap>
      <v-flex>
        <!-- <v-card> -->
        <v-card-title class="elevation-1">
          <span style="font-family: poppinsbold">{{ Name }}</span>
          <v-spacer></v-spacer>
          <!-- <v-flex pt-5 xl3 lg3 md3 pb-2 sm3 xs3 px-5>
              <v-dialog
                :retain-focus="false"
                v-model="dialog"
                persistent
                max-width="1000px"
                :key="dialog"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    outlined
                    color="red"
                    v-bind="attrs"
                    v-on="on"
                  >
                    Add
                  </v-btn>
                </template>
                <v-card>
                  <v-form v-model="addslider">
                    <v-card-title>
                      <span class="headline">Add Page Contributors</span>
                    </v-card-title>
                    <v-card-text>
                      <v-container>
                        <v-row>
                            <v-col cols="12" sm="12" md="12">
                            <v-text-field
                                v-model="Name"
                                label="Name"
                                outlined
                            ></v-text-field>                          </v-col>
                          <v-col cols="12" sm="12" md="12">
                            <div id="app">
                              <vue-editor v-model="Value"></vue-editor>
                            </div>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="dialogclose()">
                        Close
                      </v-btn>
                      <v-btn
                        color="blue darken-1"
                        text
                        :disabled="!addslider"
                        @click="add()"
                      >
                        Save
                      </v-btn>
                    </v-card-actions>
                  </v-form>
                </v-card>
              </v-dialog>
            </v-flex>  -->
          <!-- <v-flex pt-5 xl3 lg3 md3 pb-2 sm3 xs3 px-5>
              <v-dialog v-model="dialogDelete" max-width="600px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    small
                    class="mr-2"
                    outlined
                    v-on="on"
                    v-bind="attrs"
                    color="red"
                  >
                    Delete
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title
                    >Are you sure you want to delete this Careers?</v-card-title
                  >
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="dialogDelete = false"
                      >Cancel</v-btn
                    >
                    <v-btn color="blue darken-1" text @click="deleteItem()"
                      >OK</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-flex> -->
        </v-card-title>

        <div>
          <v-layout wrap>
            <v-flex pt-5 xl12 xs12 sm12 lg12 md12>
              <!-- <div v-html="staticArray.Value"></div> -->
              <div id="app">
                <vue-editor v-model="Value"></vue-editor>
              </div>
            </v-flex>
          </v-layout>
          <v-layout pt-5 pb-5 wrap justify-end>
            <v-flex xs5 sm3 md2 lg2 xl2 v-if="staticArray != null">
              <v-btn
                block
                tile
                color="#68D389"
                dark
                :ripple="false"
                depressed
                class="itemValue"
                @click="edit()"
              >
                Save Changes
              </v-btn>
            </v-flex>
            <v-flex v-else>
              <v-btn outlined color="success" @click="add()"> Add </v-btn>
            </v-flex>
          </v-layout>
        </div>
        <!-- <div v-else>
            <h4 style="padding-top: 30px">No data avilable.....</h4>
          </div> -->
        <!-- </v-card> -->
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
import { VueEditor } from "vue2-editor";
export default {
  components: {
    VueEditor,
  },
  props: ["Name"],
  data() {
    return {
      checkbox: true,
      ServerError: false,
      file: null,
      showsnackbar: false,
      editingitem: [],
      msg: null,
      pages: 0,
      Value: null,
      widthOfCard: "250px",
      appLoading: false,
      currentPage: 1,
      totalData: 0,
      totalRows: 0,
      dialogDelete: false,
      search: "",
      dialog: false,
      editdialog: false,
      staticArray: [],
      addslider: false,
      nameRules: [
        (v) => !!v || "Name is required",
        (v) => (v && v.length <= 15) || "Name must be less than 15 characters",
      ],
      Rules: [(value) => !!value || "Required."],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        url: "/static/getbyname",
        method: "GET",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          count: this.count,
          page: this.currentPage,
          Name: this.Name,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.staticArray = response.data.data;
          this.Value = this.staticArray.Value;
          this.pages = response.data.pages;
          this.totalData = response.data.count;
        })
        .catch((err) => {
          this.appLoading = false;
          this.$emit("stepper", {
            ServerError: true,
          });
          console.log(err);
        });
    },
    add() {
      var data = {};
      data["Name"] = this.Name;
      data["Value"] = this.Value;
      axios({
        url: "/static/add",
        method: "POST",
        data: data,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.getData();
            this.msg = "Added Sucessfully";
            this.showsnackbar = true;
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.$emit("stepper", {
            ServerError: true,
          });
          console.log(err);
        });
    },
    deleteItem() {
      var data = {};
      data["id"] = this.staticArray._id;
      axios({
        url: "/static/remove",
        method: "POST",
        data: data,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.dialogDelete = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Delete Sucessfully";
            this.showsnackbar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.$emit("stepper", {
            ServerError: true,
          });
          console.log(err);
        });
    },
    dialogclose() {
      this.link = null;
      this.dialog = false;
    },
    edit() {
      this.appLoading = true;
      var data = {};
      data["id"] = this.staticArray._id;
      data["Value"] = this.Value;
      axios({
        method: "POST",
        url: "/static/edit",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: data,
      })
        .then((response) => {
          this.appLoading = false;
          this.editdialog = false;
          if (response.data.status) {
            this.msg = "Edited Sucessfully";
            this.showsnackbar = true;
            this.dialog = false;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
